import 'bootstrap-daterangepicker/daterangepicker.js';

var customAnalyticsRangeLabel = 'Choisir les dates';

var loadTimeControl = function(el) {
  var dateRanges = JSON.parse(el.dataset.ranges);

  var specialRanges = loadSpecialRanges(dateRanges);
  var defaultRange = el.dataset.selected || Object.keys(specialRanges)[0];
  var defaultFrom = moment(el.dataset.from || specialRanges[defaultRange][0]);
  var defaultTo = moment(el.dataset.to || specialRanges[defaultRange][1]);

  $(el).daterangepicker(
    {
      maxDate: moment(),
      startDate: defaultFrom,
      endDate: defaultTo,
      ranges: specialRanges,
      locale: {
        format: 'DD/MM/YYYY',
        applyLabel: 'Appliquer',
        cancelLabel: 'Annuler',
        customRangeLabel: customAnalyticsRangeLabel,
        daysOfWeek: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
        monthNames: [
          'Janvier',
          'Février',
          'Mars',
          'Avril',
          'Mai',
          'Juin',
          'Juillet',
          'Août',
          'Septembre',
          'Octobre',
          'Novembre',
          'Décembre',
        ],
        firstDay: 1,
      },
    },
    refreshAnalyticsDateRange
  );

  refreshAnalyticsDateRange(defaultFrom, defaultTo, defaultRange, $(el));
};

function refreshAnalyticsDateRange(start, end, label, initiator) {
  var el = this;
  el &&= el.element;
  el ||= initiator;
  el = el[0];

  // Refresh displayed date
  if (label == customAnalyticsRangeLabel) {
    const startDate = moment(start).format('D MMM YYYY');
    const endDate = moment(end).format('D MMM YYYY');
    label = startDate + ' - ' + endDate;
  }

  el.querySelector('[data-selected-range]').innerHTML = label;

  var from = start.format('YYYY-MM-DD');
  var to = end.format('YYYY-MM-DD');

  // Save search in current url
  var currentURL = new URL(location.href);
  currentURL.searchParams.set('from', from);
  currentURL.searchParams.set('to', to);
  window.history.replaceState(null, null, currentURL.toString());

  // Refresh: 2 options
  // 1) A 'src' and 'container' params were added as data attributes to the TimeControl.
  //    An ajax request is made to the src and the response is injected in the container.
  // 2) Otherwise, we simply trigger an event to be handled by the calling component.
  var src = el.dataset.src;
  var container = el.dataset.container;
  if (src) {
    var dateRange = { from: from, to: to };
    $.ajax({
      url: src,
      data: dateRange,
      success: function(data, textStatus, jqXHR) {
        // In the case of a request with HTML format, the rendered partial is in data as HTML
        // It can be directly injected in the container
        $(container).html(data);
      },
    });
  } else {
    el.dispatchEvent(new CustomEvent('analytics:dateRangeChange', { detail: { from: from, to: to } }));
  }
}

function loadSpecialRanges(dateRanges) {
  var specialRanges = {};

  dateRanges.forEach((range) => {
    specialRanges[range.label] = [moment(range.from), moment(range.to)];
  });

  return specialRanges;
}

var loadTimeControls = function() {
  const els = document.querySelectorAll('.analytics__time-control-component');

  els.forEach((timeControl) => {
    loadTimeControl(timeControl);
  });
};

document.addEventListener('DOMContentLoaded', loadTimeControls);
document.addEventListener('analytics:timeControl:load', loadTimeControls);
