$(function() {
  window.addEventListener('neo:complete', function(e) {
    var url = e.detail.settings.url;

    if (url.includes('async=true') && url.includes('search=')) {
      var response = e.detail.xhr.responseText;
      if (response.trim().length === 0) {
        response = "Aucun résultat correspondant à la recherche n'a été trouvé.";
      }
      $('.lazy-items').html(response);
    }
  });
});
