function loadEmailEditor() {
  const selector = '#email-editor';

  const el = document.querySelector(selector);

  if (!el) return;

  const uploadPath = el.dataset.uploadPath;

  RedactorX(selector, {
    buttons: {
      addbar: ['paragraph', 'image'],
    },
    image: {
      upload: uploadPath,
      drop: true,
      width: true,
    },
    format: ['p', 'h3', 'ul', 'ol'],
    plugins: ['alignment', 'imageresize', 'imageposition'],
  });
}

document.addEventListener('DOMContentLoaded', loadEmailEditor);
window.addEventListener('neo:complete', loadEmailEditor);
